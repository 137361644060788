import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import Button from '../../../components/form-control/Button';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {  useState } from 'react';
import { Policy } from '../../../models/policy/Policy';
import { generateSticker } from '../../../api/requests';

interface EditLeadProps {
  onProceed: () => void;
  onClose: () => void;
  policy?:Policy;
}

const GenerateStickerModal = ({ onProceed, onClose, policy }: EditLeadProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="">
      <Formik
        initialValues={{
            policyNumber:policy?.ref ?? '',
          insurerPolicyNumber: policy?.insurerPolicyNumber ?? '',
          serverReference: policy?.midReference ?? '',
          stickerNumber: policy?.midStickerNumber ?? ''
        }}
        // enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
            policyNumber: Yup.string().trim().required('Phone number is required.'),
            insurerPolicyNumber: Yup.string().trim().required('Phone number is required.'),
            serverReference: Yup.string().trim().required('Phone number is required.'),
            stickerNumber: Yup.string().trim().required('Phone number is required.')
        })}
        onSubmit={(values) => {     
          const payload: any = {
            policyNumber: values.policyNumber,
            insurerPolicyNumber:values.insurerPolicyNumber,
            serverReference:values.serverReference,
            stickerNumber:values.stickerNumber
          };
          setLoading(true); 
         
            const insurerPolicyNumber = values.insurerPolicyNumber
            const policyNumber = values.policyNumber
            const serverReference = values.serverReference
            //const decodedString = decodeURIComponent(serverReference);
            //const formattedServerReference = decodedString.replace(/"/g, "").replace(/\u200B/g, "");
            const stickerNumber = values.stickerNumber
          
            generateSticker(insurerPolicyNumber, policyNumber, serverReference, stickerNumber)
            .then((response)=>{
              console.log(response);
            })
            .catch((error)=>{
          
            })
            .finally(()=>{
                setLoading(false)
            })
          
        }}>
        {({
          values,
          errors,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          touched
        }) => (
          <form className="" onSubmit={handleSubmit}>
            <div className="p-8">
              <div className=" ">
                <p className="text-[24px] mb-10 text-center">
                  Provide information to generate a sticker
                </p>
                <div className="grid w-full grid-cols-1 md:grid-cols-1 gap-5  ">
                  <Input
                    type={'text'}
                    name="policyNumber"
                    value={values.policyNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Policy number"
                    placeholder=""
                    className="rounded"
                  />
                  
                  <Input
                    type={'text'}
                    name="insurerPolicyNumber"
                    value={values.insurerPolicyNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Insurer Policy Number"
                    placeholder=""
                    className="rounded"
                  />
                  <Input
                    type={'text'}
                    name="serverReference"
                    value={values.serverReference}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Server Reference"
                    placeholder=""
                    className="rounded"
                  />
                   <Input
                    type={'text'}
                    name="stickerNumber"
                    value={values.stickerNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Sticker Number"
                    placeholder=""
                    className="rounded"
                  />
              
                </div>
              </div>
              <div className="flex-col-reverse gap-5 flex md:flex-row  mt-10 ">
                <div className="w-full md:w-1/2">
                  <Button
                    label="Cancel"
                    onClick={onClose}
                    curved
                    className=" w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                  />
                </div>

                <div className="w-full md:w-1/2">
                  <Button
                    label="Generate"
                    className="w-full px-[12px] py-[12px] h-[45px] rounded border border-primary-border hover:bg-primary-main "
                    onClick={handleSubmit}
                    disabled={loading}
                    loading={loading}
                    curved
                    bgColor="primary"
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default GenerateStickerModal;
