import Button from '../../../components/form-control/Button';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Formik } from 'formik';
import Input from '../../../components/form-control/Input';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getAdminAccounts,
  getAdmins,
  getTeam,
  setRelationshipManager,
  setStaffSupervisor
} from '../../../api/requests';
import SelectDropdown, {
  ISelectDropdownItem
} from '../../../components/form-control/SelectDropdown';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';

const SetStaffSupervisor = ({
  onProceed,
  onClose,
  staffId
}: {
  onProceed: () => void;
  onClose: () => void;
  staffId: number;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [admins, setAdmins] = useState<any>([]);

  useEffect(() => {
    getAdminAccounts().then((response)=>{
        setAdmins(response.data.slice(0,180))
    }).catch((error)=>{
    }).finally(()=>{
      setLoadingData(false)
    })
  }, [])
  

  const submitHandler = (values: any) => {
    setSubmitting(true);

    setStaffSupervisor(staffId, values.id).then((response)=>{
      onClose();
      toast.success('Staff assigned to supervisor successfully', {
        autoClose: 5000
      });
    }).catch((error)=>{
        console.log(error)
    }).finally(() => setSubmitting(false));

   
  };
  return (
    <div className="bg-white">
      <div className="bg-primary-main flex justify-between">
        <div className="p-[1rem] flex justify-between w-full items-center">
          <h2>Assign supervisor to staff</h2>
          <XMarkIcon onClick={onClose} width={'11px'} height={'11px'} className="cursor-pointer" />
        </div>
      </div>
     
      {loadingData ? (
        <div className="w-full text-center py-10 flex items-center justify-center h-full ">
          <Loader message={`Loading data . Please wait...`} brollyLoader />
        </div>
      ) : 
      (
      <>
       <div className="flex items-center justify-center mt-[26px]">
        <span className="text-center px-2">You are about to select a supervisor.</span>
      </div>
      <div className="p-[1rem] w-full">
        <Formik
          initialValues={{
            id: ''
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            // email: Yup.string().email('Email provided invalid.').required('Email is requied.')
          })}
          onSubmit={(values) => {
            submitHandler(values);
          }}>
          {({ values, errors, handleSubmit, handleChange, setFieldValue, handleBlur, touched }) => (
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <SelectDropdown
                    search={true}
                  label="Select Supervisor"
                  name="id"
                  list={admins?.map((admin: any) => ({
                    name: `${admin.firstName} ${admin.lastName}`,
                    value: admin.id
                  }))}
                  listSelectedValue={values.id}
                  onChange={(val: ISelectDropdownItem | null) => {
                    setFieldValue('id', val);
                  }}
                  onBlur={handleBlur}
                  placeholder="Select Supervisor"
                  error={touched.id ? errors.id : undefined}
                  helperText={touched?.id ? errors.id : undefined}
                  required
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  label="Submit"
                  bgColor="primary"
                  className="w-fit px-6 text-[14px]"
                  loading={submitting}
                  disabled={submitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
      
      </>)}
    </div>
  );
};

export default SetStaffSupervisor;
