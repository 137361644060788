import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo, useState } from 'react';
import PopOnMe from '../../../components/elements/poponme';
import {
  getFormattedAccessRight,
  getPhoneNumberFromShortCodeFormat,
  sentenceCase
} from '../../../utils/miscFunctions';
import Loader from '../../../components/Loader';
import GTable from '../../../components/elements/GTable';
import {
  deleteAdminAccount,
  deleteAgent,
  disableAdminAccount,
  getASingleTeam,
  getStaffAccountById,
  getTeam,
  getTeamProfile,
  sendStaffInviteLink
} from '../../../api/requests';
import { toast } from 'react-toastify';
import useUserAccount from '../../../hooks/userAccountHook';
import { User } from '../../../models/User';
import { ITableRowAction } from '../../../components/elements/GTable/GTable';
import { id } from 'date-fns/locale';
import useModal from '../../../hooks/modalHook';
import InviteTeamMember from './InviteTeamMember';
import AssignMember from './assignMember';
import { useNavigate, useParams } from 'react-router-dom';
import ChangeAccessRight from './ChangeAccessRight';
import useRoute from '../../../hooks/routesHook';
import { Staff } from '../../../models/staff/Staff';
import { ACCESS_RIGHTS } from '../../../resources/enums';
import moment from 'moment';
import ActionConfirmation from '../../../components/elements/ActionConfirmation';
import AddAdminToWorkspace from './AddAdminToWorkspace';
import UpdateStaffAccount from './UpdateStaffAccount';
import SetStaffSupervisor from './SetStaffSupervisor';

const Members = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { ModalComponent, updateModalStates, closeModal } = useModal();
  const [singleTeam, setSingleTeam] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [teamStaff, setTeamStaff] = useState<any>([]);
  const [staff, setStaff] = useState<any>([]);
  // const [staff, setStaff] = useState<any>([]);
  const { getProfile } = useUserAccount();
  const user = new User(getProfile());
  const navigate = useNavigate();
  const { getDashboardBase } = useRoute();

  const { id: teamID } = useParams();

  useEffect(() => {
    if (teamID) {
      getTeamStaff(teamID);
      getSingleTeam(teamID);

    }
  }, [])

  const getSingleTeam = (id: string) => {
    getASingleTeam(id)
      .then((response: any) => {
        setSingleTeam(response);
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editAdminAccount = (admin: any) => {
    getStaffAccountById(admin.id)
      .then((response: any) => {
        setStaff(response?.data)
        response && updateModalStates({
          show: true,
          size: 'md',
          children: (
            <UpdateStaffAccount
              staff={response?.data}
              onProceed={() => {
                closeModal();
                if (teamID) getTeamStaff(teamID);
              }}
              onClose={closeModal}
            />
          ),
          showTitleSection: false,
          classes: '!p-0 r-[5px]'
        });
      })
      .catch((error: any) => {
      })
      .finally(() => setLoading(false));

  }





  const getTeamStaff = (id: string) => {
    setLoading(true);
    getTeam(id)
      .then((response: any) => {
        setTeamStaff(response);
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const assignMember = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <AssignMember
          teamID={teamStaff.id}
          onProceed={() => {
            closeModal();
            if (teamID) getSingleTeam(teamID);
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const addAdminToWorkspace = (admin: any) => {
    getStaffAccountById(admin.id)
      .then((response: any) => {
        setStaff(response?.data)
        response && updateModalStates({
          show: true,
          size: 'md',
          children: (
            <AddAdminToWorkspace
              onProceed={() => {
                closeModal();
                if (teamID) getSingleTeam(teamID);
              }}
              onClose={closeModal}
              staff={response?.data} />
          ),
          showTitleSection: false,
          classes: '!p-0 r-[5px]'
        });

      })
      .catch((error: any) => {
        // toast.error(error?.description);
      })
      .finally(() => setLoading(false));

  };

  const inviteMember = () => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <InviteTeamMember
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const deleteAdmin = (data: any) => {
    updateModalStates({
      show: true,
      showCloseButton: false,
      children: (
        <ActionConfirmation
          action="delete"
          resourceType={data.name}
          resourceName={''}
          onClose={closeModal}
          onConfirm={() => {
            deleteAdminAccount(data.id)
              .then((response: any) => {
                if (response) {
                  closeModal();
                  toast.success(response, {
                    autoClose: 3000
                  });
                  if (teamID) getTeamStaff(teamID);
                }
              })
              .catch((error: any) => {
                closeModal();
                toast.error(error.detail, {
                  autoClose: 3000
                });
              });
          }}
        />
      )
    });
  };

  const disableAdmin = (id: string, status: boolean) => {
    disableAdminAccount(id, status)
      .then((response: any) => {
        toast.success(response?.message);
        getSingleTeam(id);
      })
      .catch((error: any) => {
        toast.error(error.description);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setStaffSupervisor = (staffId:number) => {
    updateModalStates({
      show: true,
      size: 'md',
      children: (
        <SetStaffSupervisor
        staffId={staffId}
          onProceed={() => {
            closeModal();
          }}
          onClose={closeModal}
        />
      ),
      showTitleSection: false,
      classes: '!p-0 r-[5px]'
    });
  };

  const getTableRowActions = (data: any): ITableRowAction[] => {
    const actions = [];

    if (user.isSuperAdmin()) {
      actions.push(
        {
          label: 'Edit',
          callback: () => {
            const AdminId = data.id;
            editAdminAccount(data)
          },
          data: data
        },
        {
          label: 'Change Access Right',
          callback: () => {
            const AdminId = data.id;

            updateModalStates({
              show: true,
              size: 'md',
              children: (
                <ChangeAccessRight
                  staffID={AdminId}
                  onProceed={() => {
                    closeModal();
                    if (teamID) getTeamStaff(teamID);
                  }}
                  onClose={closeModal}
                />
              ),
              showTitleSection: false,
              classes: '!p-0 r-[5px]'
            });
          },
          data: data
        },
        {
          label: 'Remove member',
          callback: () => {
            deleteAdmin(data);
          },
          data: data
        },
        {
          label: data.activated ? 'Deactivate' : 'Activate',
          callback: () => {
            const agentId = data?.id;
            disableAdmin(agentId, !data?.activated);
          },
          data: data
        },
        {
          label: 'Assign Staff To Supervisor',
          callback: async () => {
            setStaffSupervisor(data.id);
          },
          data: data
        },
        {
          label: 'Add to workspace',
          callback: () => {
            addAdminToWorkspace(data);
          },
          data: data
        }
      );
    }

    if (!data.verified) {
      actions.push(
        {
          label: 'Resend invite email',
          callback: () => resendInviteEmail(data),
          data: data
        }
        // {
        //   label: 'Copy invite link',
        //   callback: () => {

        //   },
        //   data: data
        // }
      );
    }

    return actions;
  };

  const resendInviteEmail = (data: any) => {
    sendStaffInviteLink(data.id)
      .then((response: any) => {
        if (response) {
          toast.success(response.message, {
            autoClose: 4000
          });
        }
      })
      .catch((error: any) => {
        toast.error(error.description, {
          autoClose: 4000
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'M',
        columns: [
          {
            Header: 'Name',
            accessor: 'name',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Email',
            accessor: 'email',
            Cell: (row: any) => {
              return <span>{row.cell.value ? row.cell.value : 'N/A'}</span>;
            }
          },
          {
            Header: 'Phone',
            accessor: 'phone',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? getPhoneNumberFromShortCodeFormat(row.cell.value) : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Address',
            accessor: 'address',
            Cell: (row: any) => {
              return <span>{row.cell.value ? sentenceCase(row.cell.value) : 'N/A'}</span>;
            }
          },
          {
            Header: 'Role',
            accessor: 'role',
            Cell: (row: any) => {
              return (
                <span>
                  {row.cell.value ? sentenceCase(getFormattedAccessRight(row.cell.value)) : 'N/A'}
                </span>
              );
            }
          },
          {
            Header: 'Verified',
            accessor: 'verified',
            Cell: (row: any) => {
              return (
                <div className="">
                  {row.cell.value ? (
                    <div className="h-8 w-8 bg-[#0F7125] flex  items-center justify-center text-white rounded-full "></div>
                  ) : (
                    <div className="h-8 w-8 bg-red-700 flex  items-center justify-center text-white rounded-full "></div>
                  )}
                </div>
              );
            }
          },
          {
            Header: 'Status',
            accessor: 'activated',
            Cell: (row: any) => {
              return (
                <div className="">
                  {row.cell.value ? (
                    <div className="py-1 px-2 bg-[#0F7125] flex  items-center justify-center text-white rounded ">
                      Active
                    </div>
                  ) : (
                    <div className="py-1 px-2 bg-[#FBC02D] flex  items-center justify-center text-white rounded ">
                      <span>Inactive</span>
                    </div>
                  )}
                </div>
              );
            }
          },

          {
            Header: 'Last Seen',
            accessor: 'lastSeen',
            Cell: (row: any) => {
              return <span>{row.cell.value ? moment(row.cell.value).format('lll') : 'N/A'}</span>;
            }
          },
          {
            id: 'Action',
            Cell: (row: any) => {
              return (
                <>
                  {getTableRowActions.length > 0 && (
                    <div className="cursor-pointer flex justify-center">
                      <PopOnMe actions={getTableRowActions(row.row.original)}>
                        <EllipsisVerticalIcon className="h-4 w-4" />
                      </PopOnMe>
                    </div>
                  )}
                </>
              );
            }
          }
        ]
      }
    ],
    []
  );

  return (
    <div>
      {(user.isSuperAdmin() || user.isAdmin() || user.isReviewer()) && (
        <div className="rounded-md h-full justify-between px-2 pb-3 pt-7 align-items-center">
          <div className="overflow-x-auto shadow-md ">
            {loading ? (
              <div className="h-screen flex items-center justify-center bg-white">
                <Loader message="Fetching team members. Please wait..." brollyLoader />
              </div>
            ) : (
              <>
                {teamStaff ? (
                  <>
                    {user.isSuperAdmin() || user.isReviewer() ? (
                      <div className="overflow-x-auto shadow-md">
                        <GTable
                          columns={columns}
                          data={teamStaff}
                          tableType={'members'}
                          showSearch={false}
                          classes="h-fit"
                          onRowClick={(staff: Staff) => {
                            const managerId = staff.id;
                            if (managerId)
                              navigate(`${getDashboardBase()}/teams/staff/${managerId}`);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="overflow-x-auto shadow-md">
                        <GTable
                          columns={columns}
                          data={teamStaff}
                          tableType={'members'}
                          showSearch={false}
                          classes="h-fit"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className=" h-40 text-center flex items-center justify-center font-bold">
                    <h2>No staff found.</h2>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <ModalComponent />
    </div>
  );
};

export default Members;
